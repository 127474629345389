import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextInput,
  Button,
} from "@contentful/forma-36-react-components";
import { FieldExtensionSDK } from "contentful-ui-extensions-sdk";
import i18n from "../../i18n";

type DailySchedulesProperties = { sdk: FieldExtensionSDK };

interface DailySchedule {
  day: string;
  operatingState: "OPEN" | "CLOSED" | undefined;
  fromTime?: string;
  toTime?: string;
  lastDescentTime?: string;
}

const DailySchedules: React.FC<DailySchedulesProperties> = ({ sdk }) => {
  const [dailySchedules, setDailySchedules] = useState<Array<DailySchedule>>(
    sdk.field.getValue() || []
  );

  useEffect(() => {
    sdk.field.setValue(dailySchedules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailySchedules]);

  const initializeDailySchedules = () => {
    setDailySchedules([
      { day: "MONDAY", operatingState: "CLOSED" },
      { day: "TUESDAY", operatingState: "CLOSED" },
      { day: "WEDNESDAY", operatingState: "CLOSED" },
      { day: "THURSDAY", operatingState: "CLOSED" },
      { day: "FRIDAY", operatingState: "CLOSED" },
      { day: "SATURDAY", operatingState: "CLOSED" },
      { day: "SUNDAY", operatingState: "CLOSED" },
    ]);
  };

  const toggleDay = (isChecked: boolean, index: number) => {
    const updatedSchedules = dailySchedules.map((schedule, i) =>
      i === index
        ? ({
            ...schedule,
            operatingState: isChecked ? "OPEN" : "CLOSED",
            fromTime: isChecked ? schedule.fromTime : "",
            toTime: isChecked ? schedule.toTime : "",
            lastDescentTime: isChecked ? schedule.lastDescentTime : "",
          } as DailySchedule)
        : schedule
    );

    setDailySchedules(updatedSchedules);
  };

  const updateTime = (
    index: number,
    field: keyof DailySchedule,
    value: string
  ) => {
    const updatedSchedules = dailySchedules.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    );

    setDailySchedules(updatedSchedules);
  };

  const onToggle = (isChecked: boolean, index: number) =>
    toggleDay(isChecked, index);

  const onFromTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => updateTime(index, "fromTime", event.target.value);

  const onToTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => updateTime(index, "toTime", event.target.value);

  const onLastDescentTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => updateTime(index, "lastDescentTime", event.target.value);

  return (
    <>
      {dailySchedules.length === 0 ? (
        <Button buttonType="naked" onClick={() => initializeDailySchedules()}>
          {i18n.addDailyOpeningHours}
        </Button>
      ) : (
        <>
          <Table>
            <TableBody>
              {dailySchedules.map((dailySchedule, index) => (
                <TableRow key={dailySchedule.day}>
                  <TableCell
                    style={{
                      paddingTop: "25px",
                      paddingBottom: "25px",
                      width: "25%",
                    }}
                  >
                    {dailySchedule.day}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: "25px",
                      paddingBottom: "25px",
                      width: "5%",
                    }}
                  >
                    <Switch
                      id={dailySchedule.day}
                      isChecked={dailySchedule.operatingState === "OPEN"}
                      labelText={""}
                      onToggle={(isChecked) => onToggle(isChecked, index)}
                    />
                  </TableCell>
                  <TableCell
                    style={
                      dailySchedule.operatingState === "OPEN"
                        ? {}
                        : {
                            paddingTop: "25px",
                            paddingBottom: "25px",
                          }
                    }
                  >
                    {dailySchedule.operatingState === "OPEN" ? (
                      <div
                        style={{
                          display: "grid",
                          gridGap: "10px",
                          gridRowGap: "20px",
                          gridTemplateColumns: "1fr 1fr 1.3fr",
                        }}
                      >
                        <React.Fragment key={index}>
                          <TextInput
                            value={dailySchedule.fromTime || ""}
                            placeholder={i18n.opensAt}
                            onChange={(event) => onFromTimeChange(event, index)}
                            list="hours"
                            style={{ boxSizing: "border-box" }}
                          />
                          <TextInput
                            value={dailySchedule.toTime || ""}
                            placeholder={i18n.closesAt}
                            onChange={(event) => onToTimeChange(event, index)}
                            list="hours"
                            style={{ boxSizing: "border-box" }}
                          />
                          <TextInput
                            value={dailySchedule.lastDescentTime || ""}
                            placeholder={i18n.lastDescentAt}
                            onChange={(event) =>
                              onLastDescentTimeChange(event, index)
                            }
                            list="hours"
                            style={{ boxSizing: "border-box" }}
                          />
                        </React.Fragment>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>{i18n.closed}</div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <span>
            <Button buttonType="naked" onClick={() => setDailySchedules([])}>
              {i18n.removeEntry}
            </Button>
          </span>
        </>
      )}

      <datalist id="hours">
        {Array.from(Array(23)).map((a, i) => {
          const hour = `${i}`.padStart(2, "0");
          return (
            <React.Fragment key={i}>
              <option value={`${hour}:00`} />
              <option value={`${hour}:05`} />
              <option value={`${hour}:10`} />
              <option value={`${hour}:15`} />
              <option value={`${hour}:20`} />
              <option value={`${hour}:25`} />
              <option value={`${hour}:30`} />
              <option value={`${hour}:35`} />
              <option value={`${hour}:40`} />
              <option value={`${hour}:45`} />
              <option value={`${hour}:50`} />
              <option value={`${hour}:55`} />
            </React.Fragment>
          );
        })}
      </datalist>
    </>
  );
};

export default DailySchedules;
