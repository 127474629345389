import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DailySchedules from "./dailySchedules/DailySchedulesApp";
import WeeklySchedule from "./weeklySchedule/WeeklyScheduleApp";
import { KnownSDK } from "contentful-ui-extensions-sdk";

function App({ sdk }: { sdk: KnownSDK }) {
  return (
    <Router>
      <Routes>
        <Route
          path="/apps/dailySchedules"
          element={<DailySchedules sdk={sdk} />}
        />
        <Route
          path="/apps/weeklySchedule"
          element={<WeeklySchedule sdk={sdk} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
