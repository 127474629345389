type i18n = {
  loading: string;
  contentTypes: string;
  noConfig: string;
  weekDays: string[];
  opeaddHoursnsAt: string;
  opensAt: string;
  closesAt: string;
  closed: string;
  open: string;
  chooseDate: string;
  addSpecialHours: string;
};

const i18n = {
  loading: "Loading...",
  contentTypes: "Content types",
  noConfig: "No configuration available",
  weekDays: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  addHours: "Add hours",
  opensAt: "Opens at",
  closesAt: "Closes at",
  lastDescentAt: "Last descent at",
  closed: "Closed",
  removeEntry: "Remove entry",
  open: "Open",
  chooseDate: "Choose a date",
  addSpecialHours: "Add special hours",
  addSeasonalHours: "Add seasonal hours",
  addDailyOpeningHours: "Add daily opening hours",
  addWeeklyOpeningHours: "Add weekly opening hours",
  openingHours: "Opening hours",
};

export default i18n;
