import React from "react";
import {
  AppExtensionSDK,
  FieldExtensionSDK,
  KnownSDK,
  locations,
} from "contentful-ui-extensions-sdk";
import WeeklyScheduleField from "./WeeklyScheduleField";
import AppConfig from "../AppConfig";
import i18n from "../../i18n";

export default function WeeklyScheduleApp({ sdk }: { sdk: KnownSDK }) {
  const isConfig = sdk.location.is(locations.LOCATION_APP_CONFIG);
  const isField = sdk.location.is(locations.LOCATION_ENTRY_FIELD);

  if (isConfig) {
    return <AppConfig sdk={sdk as AppExtensionSDK}>{i18n.noConfig}</AppConfig>;
  } else if (isField) {
    return <WeeklyScheduleField sdk={sdk as FieldExtensionSDK} />;
  }

  return null;
}
