import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextInput,
  Button,
} from "@contentful/forma-36-react-components";
import { FieldExtensionSDK } from "contentful-ui-extensions-sdk";
import i18n from "../../i18n";

type WeeklyScheduleProperties = { sdk: FieldExtensionSDK };

interface WeeklySchedule {
  operatingState?: "OPEN" | "CLOSED" | undefined;
  fromTime?: string;
  toTime?: string;
  lastDescentTime?: string;
}

const WeeklySchedule: React.FC<WeeklyScheduleProperties> = ({ sdk }) => {
  const [weeklySchedule, setWeeklySchedule] = useState<WeeklySchedule | null>(
    sdk.field.getValue() || null
  );

  useEffect(() => {
    sdk.field.setValue(weeklySchedule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklySchedule]);

  const initializeWeeklySchedule = () =>
    setWeeklySchedule({ operatingState: "CLOSED" });

  const toggleSchedule = (isChecked: boolean, schedule: WeeklySchedule) => {
    const editedSchedule: WeeklySchedule = {
      operatingState: isChecked ? "OPEN" : "CLOSED",
      fromTime: isChecked ? schedule.fromTime : "",
      toTime: isChecked ? schedule.toTime : "",
      lastDescentTime: isChecked ? schedule.lastDescentTime : "",
    };
    setWeeklySchedule(editedSchedule);
  };

  const updateTime = (field: keyof WeeklySchedule, value: string) => {
    const editedSchedule: WeeklySchedule = {
      ...weeklySchedule,
      [field]: value,
    };
    setWeeklySchedule(editedSchedule);
  };

  const onFromTimeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateTime("fromTime", event.target.value);
  const onToTimeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateTime("toTime", event.target.value);

  const onLastDescentTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => updateTime("lastDescentTime", event.target.value);

  return (
    <>
      {weeklySchedule === null ? (
        <span>
          <Button buttonType="naked" onClick={() => initializeWeeklySchedule()}>
            {i18n.addWeeklyOpeningHours}
          </Button>
        </span>
      ) : (
        <>
          <Table>
            <TableBody>
              <TableRow key={0}>
                <TableCell
                  style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    width: "25%",
                  }}
                >
                  {i18n.openingHours}
                </TableCell>
                <TableCell
                  style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    width: "5%",
                  }}
                >
                  <Switch
                    id={"weeklySchedule"}
                    isChecked={weeklySchedule.operatingState === "OPEN"}
                    labelText={""}
                    onToggle={(isChecked) =>
                      toggleSchedule(isChecked, weeklySchedule)
                    }
                  />
                </TableCell>
                <TableCell
                  style={
                    weeklySchedule.operatingState === "OPEN"
                      ? {}
                      : {
                          paddingTop: "25px",
                          paddingBottom: "25px",
                        }
                  }
                >
                  {weeklySchedule.operatingState === "OPEN" ? (
                    <div
                      style={{
                        display: "grid",
                        gridGap: "10px",
                        gridRowGap: "20px",
                        gridTemplateColumns: "1fr 1fr 1.3fr",
                      }}
                    >
                      <React.Fragment key={0}>
                        <TextInput
                          value={weeklySchedule.fromTime || ""}
                          placeholder={i18n.opensAt}
                          onChange={(event) => onFromTimeChange(event)}
                          list="hours"
                          style={{ boxSizing: "border-box" }}
                        />
                        <TextInput
                          value={weeklySchedule.toTime || ""}
                          placeholder={i18n.closesAt}
                          onChange={(event) => onToTimeChange(event)}
                          list="hours"
                          style={{ boxSizing: "border-box" }}
                        />
                        <TextInput
                          value={weeklySchedule.lastDescentTime || ""}
                          placeholder={i18n.lastDescentAt}
                          onChange={(event) => onLastDescentTimeChange(event)}
                          list="hours"
                          style={{ boxSizing: "border-box" }}
                        />
                      </React.Fragment>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>{i18n.closed}</div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <span>
            <Button buttonType="naked" onClick={() => setWeeklySchedule(null)}>
              {i18n.removeEntry}
            </Button>
          </span>
        </>
      )}

      <datalist id="hours">
        {Array.from(Array(23)).map((_a, i) => {
          const hour = `${i}`.padStart(2, "0");
          return (
            <React.Fragment key={i}>
              <option value={`${hour}:00`} />
              <option value={`${hour}:05`} />
              <option value={`${hour}:10`} />
              <option value={`${hour}:15`} />
              <option value={`${hour}:20`} />
              <option value={`${hour}:25`} />
              <option value={`${hour}:30`} />
              <option value={`${hour}:35`} />
              <option value={`${hour}:40`} />
              <option value={`${hour}:45`} />
              <option value={`${hour}:50`} />
              <option value={`${hour}:55`} />
            </React.Fragment>
          );
        })}
      </datalist>
    </>
  );
};

export default WeeklySchedule;
