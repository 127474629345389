import React from "react";
import { createRoot } from "react-dom/client";
import { init, KnownSDK, WindowAPI } from "contentful-ui-extensions-sdk";
import "@contentful/forma-36-react-components/dist/styles.css";
import "@contentful/forma-36-fcss/dist/styles.css";
import ContentfulApp from "./apps";

const root = createRoot(document.getElementById("root") as HTMLElement); // Create a root.

type HasWindowAPI = { window: WindowAPI };

init((sdk: KnownSDK) => {
  root.render(<ContentfulApp sdk={sdk} />); // Use the root to render.

  if ((sdk as HasWindowAPI).window) {
    (sdk as HasWindowAPI).window.startAutoResizer();
  }
});
