import React from "react";
import { Workbench } from "@contentful/forma-36-react-components";
import { AppExtensionSDK } from "contentful-ui-extensions-sdk";

export default function AppConfig({
  sdk,
  children,
}: {
  sdk: AppExtensionSDK;
  children: React.ReactNode;
}) {
  const { app } = sdk as AppExtensionSDK;

  app.setReady();

  return (
    <Workbench className="f36-font-family--sans-serif f36-color--text-dark">
      <Workbench.Content type="full">{children}</Workbench.Content>
    </Workbench>
  );
}
